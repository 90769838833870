<template>
  <div class="g23">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button v-if="errorKeys.length" type="primary" @click="handleErrorClick">下一错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="spreadContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import SpreadMixin from '@/mixins/SpreadMixin'
import auth from '@/common/auth'
import prepareMaterialListService from '@/services/prepareMaterialListService'
import G23SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G23SpreadInfo'
import G23Model from '@/model/G23Model'
import g23Service from '@/services/g23Service'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'

export default {
  name: 'g23',
  mixins: [
    SpreadMixin
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      materialList: [],
      materialListMap: {},
      g23List: [],
      spreadInfo: G23SpreadInfo,
      currentErrorIndex: 0
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errorMap)
    },
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      if (Object.keys(this.errorMap).length) {
        this.$message({
          type: 'error',
          message: '数据存在问题，请修正后再保存！'
        })
        return
      }
      this.$myLoading()
      this.translate()
      this.pushData()
    },
    handleErrorClick () {
      let rowIndex = this.errorMap[this.errorKeys[this.currentErrorIndex]].rowIndex
      this.worksheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
      this.currentErrorIndex = (this.currentErrorIndex + 1) % this.errorKeys.length
    },
    setProtection () {
      this.worksheet.options.isProtected = true
      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.backColor = '#aea'
      unLockStyle.locked = false

      this.worksheet.setStyle(-1, 5, unLockStyle)
      this.worksheet.setStyle(-1, 7, unLockStyle)
    },
    bindCellChanged () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ValueChanged, (sender, info) => {
        this.rowCompute(info.row)
      })
    },
    bindDragFillBlockCompleted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this.rowCompute(info.fillRange.row + i)
        }
      })
    },
    bindClipboardPasted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        for (let i = 0; i < info.cellRange.rowCount; ++i) {
          this.rowCompute(i + info.cellRange.row)
        }
      })
    },
    rowCompute (rowIndex) {
      let materialListNum = this.worksheet.getValue(rowIndex, 4)
      let firstYearNum = this.worksheet.getValue(rowIndex, 5)
      let secondYearNum = this.worksheet.getValue(rowIndex, 7)
      let firstYearPercent = 0
      let secondYearPercent = 0
      let flag = false

      if ((firstYearNum && firstYearNum.toString().length) || (secondYearNum && secondYearNum.toString().length)) {
        flag = true
      }

      if (utility.isNumber(firstYearNum)) {
        firstYearPercent = Math.round(10000 * utility.floatDivide(firstYearNum, materialListNum)) / 100
        this.worksheet.setValue(rowIndex, 6, firstYearPercent)
      } else {
        this.worksheet.setValue(rowIndex, 6, '')
      }
      if (utility.isNumber(secondYearNum)) {
        secondYearPercent = Math.round(10000 * utility.floatDivide(secondYearNum, materialListNum)) / 100
        this.worksheet.setValue(rowIndex, 8, secondYearPercent)
      } else {
        this.worksheet.setValue(rowIndex, 8, '')
      }
      if (utility.isNumber(firstYearNum) && utility.isNumber(secondYearNum)) {
        this.worksheet.setValue(rowIndex, 9, utility.floatSubstract(utility.floatSubstract(materialListNum, firstYearNum), secondYearNum))
        this.worksheet.setValue(rowIndex, 10, utility.floatSubstract(utility.floatSubstract(100, firstYearPercent), secondYearPercent))
      } else {
        this.worksheet.setValue(rowIndex, 9, '')
        this.worksheet.setValue(rowIndex, 10, '')
      }

      let thirdYearNum = this.worksheet.getValue(rowIndex, 9)
      if (flag && (!utility.isNumber(thirdYearNum) || thirdYearNum < 0)) {
        this.setError('请检查前两年工程量是否填写正确', rowIndex, 9)
        this.setError('请检查前两年工程量是否填写正确', rowIndex, 10)
      } else {
        this.setError(null, rowIndex, 9)
        this.setError(null, rowIndex, 10)
      }
      this.currentErrorIndex = 0
    },
    translate () {
      let dataTable = this.spread.toJSON({
        ignoreStyle: true
      }).sheets[this.spreadInfo.sheetName].data.dataTable
      console.log(dataTable)

      this.g23List = []
      for (let key in Object.keys(dataTable)) {
        let item = dataTable[key]
        if (!item[5] || !item[7]) {
          continue
        }
        console.log('key item', key, item)
        let g23Model = new G23Model({ planStatExaminationGuid: this.planStatExaminationGuid, materialListGuid: this.materialListMap[parseInt(key)].materialListGuid, tenderGuid: auth.getUserInfo().tenderGuid })
        g23Model.firstYearNum = parseFloat(item[5].value)
        g23Model.firstYearPercent = parseFloat(item[6].value)
        g23Model.secondYearNum = parseFloat(item[7].value)
        g23Model.secondYearPercent = parseFloat(item[8].value)
        g23Model.thirdYearNum = parseFloat(item[9].value)
        g23Model.thirdYearPercent = parseFloat(item[10].value)
        g23Model.init()

        if (utility.isNumber(g23Model.firstYearNum) && utility.isNumber(g23Model.secondYearNum)) {
          if (g23Model.firstYearNum !== 0 || g23Model.secondYearNum !== 0 || g23Model.thirdYearNum !== 0) {
            this.g23List.push(g23Model)
          }
        }
      }
    },
    pushData () {
      g23Service.batchAdd(this.g23List)
        .then(res => {
          console.log(res)
          this.$loading().close()
          this.$message({
            type: 'success',
            message: '操作成功！'
          })
          this.$router.push('/g23Index')
        })
        .catch(err => {
          console.log(err)
          this.$loading().close()
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    bindMaterialList () {
      // 设定表格的行数
      this.worksheet.setRowCount(this.materialList.length)
      let dataArray = []
      // 绑定数据
      this.materialList.forEach((item, index) => {
        let rowData = []
        rowData.push(index + 1)
        rowData.push(item.parentMaterialList.materialListName)
        rowData.push(item.materialListName)
        rowData.push(item.unit)
        rowData.push(item.num)
        rowData.push('')
        rowData.push('')
        rowData.push('')
        rowData.push('')
        rowData.push('')
        rowData.push('')
        dataArray.push(rowData)

        // 附带算一下materialListMap
        this.materialListMap[index] = item
      })
      this.worksheet.setArray(0, 0, dataArray)
    },
    getG23List () {
      g23Service.list({ tenderGuid: this.tenderGuid, planStatExaminationGuid: this.planStatExaminationGuid })
        .then(res => {
          this.getMaterialList(res.data.data.map(item => item.materialListGuid))
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    getMaterialList (exceptData) {
      if (!exceptData) {
        exceptData = []
      }
      prepareMaterialListService.list({}, { tenderGuid: auth.getUserInfo().tenderGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.materialList = res.data.data.filter(item => {
              if (item.num === 0) {
                return false
              }
              if (exceptData.indexOf(item.materialListGuid) !== -1) {
                return false
              }
              return true
            })

            this.bindMaterialList()
            this.setProtection()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err.request.response)
        })
    }
  },
  mounted () {
    this.initWorkbook(this.$refs.spreadContainer)
    this.getG23List()
    this.bindCellChanged()
    this.bindClipboardPasted()
    this.bindDragFillBlockCompleted()
  }
}
</script>

<style scoped lang="scss">
.g23 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-container {
    flex: 1;
  }
}
</style>
