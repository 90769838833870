const G23SpreadInfo = {
  sheetName: 'G23',
  headerRowCount: 2,
  headerColumnCount: 11,
  headerColumns: [
    {
      index: 0,
      name: '序号',
      width: '110'
    },
    {
      index: 1,
      name: '材料名称及规格',
      width: '100',
      children: [
        {
          index: 1,
          name: '工程部位',
          width: '150'
        },
        {
          index: 2,
          name: '材料名称',
          width: '200'
        }
      ]
    },
    {
      index: 3,
      name: '单位',
      width: '60'
    },
    {
      index: 4,
      name: '计划备料总量',
      width: '120'
    },
    {
      index: 5,
      name: '第一年度',
      children: [
        {
          index: 5,
          name: '计划备料量',
          width: '90'
        },
        {
          index: 6,
          name: '占总量（%）',
          width: '90'
        }
      ]
    },
    {
      index: 7,
      name: '第二年度',
      children: [
        {
          index: 7,
          name: '计划备料量',
          width: '90'
        },
        {
          index: 8,
          name: '占总量（%）',
          width: '90'
        }
      ]
    },
    {
      index: 9,
      name: '第三年度',
      children: [
        {
          index: 9,
          name: '计划备料量',
          width: '90'
        },
        {
          index: 10,
          name: '占总量（%）',
          width: '90'
        }
      ]
    }
  ]
}

export default G23SpreadInfo
